import React from "react";
import "../assets/styles.css";
import Hero from "../components/Hero";
import Roadmap from "../components/Roadmap";
import About from "../components/About";
import NavBar from "../components/NavBar";
import Tokenomics from "../components/Tokenomics";


import Faq from "../components/Faq";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <div className="home">
            <NavBar />
            <Hero />
            {/* <About />
            <Tokenomics/>
            <Roadmap /> */}
            <Faq />
            <Footer />
        </div>
    )
};

export default Home;
