import { Home, MintPage, NFTVault, BossGame } from './pages'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mint-page" element={<MintPage />} />
          <Route path="/nft-vault" element={<NFTVault />} />
          <Route path="/boss-quest" element={<BossGame />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
