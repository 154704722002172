import React from 'react';
import Modal from 'react-modal';
import "../assets/alert.css";

Modal.setAppElement('#root');

const AlertModal = ({ isOpen, message, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="modalContent"
            overlayClassName="modalOverlay"
            contentLabel="Alert Modal"
        >
            <p className="modalMessage">
                {message}
            </p>
            <button 
                className="alertModalClose" 
                onClick={onClose}
            >
                Close
            </button>
        </Modal>
    );
};

export default AlertModal;
