import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useDispatch, useSelector } from "react-redux";
import {
    useAccount,
    useNetwork,
    useBalance 
  } from 'wagmi';
import { updateAccountData, disconnect } from "../features/blockchain";
import { useEffect } from 'react';
import networks from "../utils/networksMap.json";
import { networkDeployedTo } from "../utils/contracts-config";

const Connect = () => {
    const dispatch = useDispatch();
    const stateData = useSelector((state) => state.blockchain.value);

    console.log(stateData);
    const { address, isConnected  } = useAccount();
    const { chain, chains } = useNetwork()
    const { data, isError, isLoading } = useBalance({
        address: address
      })
      useEffect(() => {
        if(isConnected & chain?.id === parseInt(networkDeployedTo, 10) ){
            dispatch(
                updateAccountData({
                  account: address,
                  balance: data?.formatted,
                  network: networks[String(chain.id)]
                })
              )
              }
              else{
                dispatch(disconnect());
          }
    
      }, [isConnected, chain])
      
  return (
<ConnectButton/>
  )
}

export default Connect