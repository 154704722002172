import React from "react";
import "../assets/hero.css";
// Import FontAwesome components and specific icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem, faVault } from "@fortawesome/free-solid-svg-icons"; // Adjust these imports based on the icons you want to use

function Hero() {
  return (
    <section className="heroboss">
      <div className="herocontent">
        {/* Hero Title */}
        <div className="hero-title">El Patron is here</div>

        {/* Hero Subtitle */}
        <div className="hero-subtitle">Join the cartel.</div>

        {/* Hero Description */}
        <div className="hero-description">NFT - DeFi on Blast L2</div>

        {/* Buttons */}
        <div className="herobuttons">
          <a href="/mint-page" className="button-mint" aria-label="mint">
            {/* FontAwesome Icon for Mint */}
            <FontAwesomeIcon icon={faGem} /> Mint
          </a>
          <a href="/nft-vault" className="button-vaults" aria-label="vaults">
            {/* FontAwesome Icon for Vaults */}
            <FontAwesomeIcon icon={faVault} /> Vaults
          </a>
        </div>
      </div>
    </section>
  );
}

export default Hero;
