import React, { useState, useEffect } from "react";
import "../assets/navbar.css";
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

import Connect from "./Connect";
import logo from "../assets/img/pat.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons'; // Importing a solid book icon as a substitute for GitBook

const NavBarLink = ({ href, children }) => (
  <strong>
    <a href={href} className="nav-link_ref">
      {children}
    </a>
  </strong>
);

const links = [
  { href: "/", title: "Home" },
  { href: "/mint-page", title: "Mint El's" },
  { href: "/nft-vault", title: "El's Vault" },
  // Additional links can be uncommented or added here
];

function NavBar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isNavExpanded ? 'hidden' : 'auto';
  }, [isNavExpanded]);

  return (
    <header className="header" role="banner">
      <div className="brand">
        <a href="/" aria-label="Home">
          <img src={logo} alt="Logo" className="logo" />
        </a>
        <div className="mobile-cnt-btn">
          <Connect />
        </div>
        <button
          className="nav-burger"
          onClick={() => setIsNavExpanded(!isNavExpanded)}
          aria-label="Menu"
        >
          <AiOutlineMenu size={30} color={"#FFF"} />
        </button>
      </div>
      <div className="nav-contents">
        <nav className={isNavExpanded ? "nav-custom open-menu" : "nav-custom"}>
          <button
            className={isNavExpanded ? "nav-cancel" : "nav-cancel is-active"}
            onClick={() => setIsNavExpanded(!isNavExpanded)}
            aria-label="Close menu"
          >
            <AiOutlineClose size={30} color={"#FFF"} />
          </button>
          <div className="nav-links_div">
            {links.map((link) => (
              <NavBarLink key={link.href} href={link.href}>
                {link.title}
              </NavBarLink>
            ))}
          </div>
          <div className="social-links">
            <a href="https://twitter.com/CryptoBoss_xyz" className="social-link">
              <FontAwesomeIcon icon={faTwitter} /> Twitter
            </a>
            <a href="https://docs.el-patron.xyz" className="social-link">
              <FontAwesomeIcon icon={faBook} /> Docs
            </a>
          </div>
        </nav>
        <div className="decktop-cnt-btn">
          <Connect />
        </div>
      </div>
    </header>
  );
}

export default NavBar;
