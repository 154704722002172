import React, { useState } from 'react';
import '../assets/faq.css';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const faqs = [
        {
            question: 'El Patron. What does it mean?',
            answer: 'El Patron is a groundbreaking NFT DeFi project that intersects DeFi with Unique NFTs. Built on Blast L2',
        },
        {
            question: 'When can I buy El Patron NFT?',
            answer: 'To be Communicated',
        },
        {
            question: 'is there a token? Where can i buy?',
            answer: 'Yes, To be communicated',
        },
        {
            question: 'What wallet can i use?',
            answer: 'We currently support Metamask, Coinbase, Trustwallet and others',
        },
        {
            question: 'What is the price of El Patron NFT?',
            answer: 'To be communicated',
        },
        {
            question: 'Staking El Patron? Where can i stake?',
            answer: 'You can stake your Boss NFT directly from our app in the El Vault page where you can also stake to the vault of your choice.',
        },
    ];

    return (
        <div className="faq-container" id="FAQ">
            <h1 className="faq-title"><span>Frequently</span> <span>asked questions</span></h1>
            <div className="faq-questions">
                {faqs.map((faq, index) => (
                   <div className={`faq-item ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => handleClick(index)}>
                   <header>{faq.question} {activeIndex === index ? <AiOutlineMinus /> : <AiOutlinePlus />}</header>
                   <p className={activeIndex === index ? 'active' : ''}>{faq.answer}</p>
               </div>
               
                ))}
            </div>
        </div>
    );
};

export default Faq;
