import React, { useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import BossLogo from "../assets/img/BossLogo.png";
import "../assets/Tokenomics.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWater,
  faExchangeAlt,
  faChartBar,
  faDollarSign,
  faTag,
  faTractor,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";

// Initialize Apollo Client
const client = new ApolloClient({
  uri: "https://api.studio.thegraph.com/query/47147/dackieswap/v0.0.1",
  cache: new InMemoryCache(),
});

// Define your GraphQL query
const TOKEN_DATA = gql`
  query GetTokenData {
    token(id: "0xb4e4127551265ff0f3b6c98e591520e6eeff3f39") {
      id
      name
      symbol
      totalLiquidity
      totalTransactions
      tradeVolume
      tradeVolumeUSD
      decimals
      derivedUSD
      derivedETH
    }
  }
`;

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8B008B",
  "#FF4500",
];

// Pie chart data for tokenomics
const PIE_DATA = [
  { name: "Staking Rewards", value: 400000 },
  { name: "Public Token Sale", value: 100000 },
  { name: "Gaming Utility", value: 150000 },
  { name: "Liquidity Provision", value: 150000 },
  { name: "Team and Development", value: 100000 },
  { name: "Community Initiatives", value: 100000 },
];

const Tokenomics = () => {
  const { loading, error, data, startPolling, stopPolling } =
    useQuery(TOKEN_DATA);

  useEffect(() => {
    startPolling(60000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  if (loading) return <div className="loader">
  <div data-glitch="Loading..." className="glitch">Loading...</div>
</div>;
  if (error) return
   <div className="loader">
  <div data-glitch="Loading..." className="glitch">Loading...</div>
</div>;

  const token = data.token;
  return (
    <div className="tokenomics">
      <div className="roadmap-bg absolute bottom-24 left-24" />

      <div className="roadmap-bg " style={{ top: "100px", right: "100px" }} />
      <h1 className="title">
        Token Overview of {token.name} ({token.symbol})
      </h1>
      <div className="tokenDetails">
        <img src={BossLogo} alt="Token Logo" className="tokenLogo" />

        <div className="details">
          <div className="detailBox priceUSD">
            <FontAwesomeIcon icon={faTag} />
            <p>Price (USD)</p>
            <p>${Number(token.derivedUSD).toLocaleString()}</p>
          </div>
          <div className="detailBox priceETH">
            <FontAwesomeIcon icon={faEthereum} />
            <p>Price(ETH) </p>
            <p>{Number(token.derivedETH).toLocaleString()}</p>
          </div>
          <div className="detailBox totalLiquidity">
            <FontAwesomeIcon icon={faWater} />
            <p>Total Liquidity </p>
            <p>{Number(token.totalLiquidity).toLocaleString()}</p>
          </div>
          <div className="detailBox totalTransactions">
            <FontAwesomeIcon icon={faExchangeAlt} />
            <p>Total Transactions </p>
            <p>{Number(token.totalTransactions).toLocaleString()}</p>
          </div>
          <div className="detailBox tradeVolume">
            <FontAwesomeIcon icon={faChartBar} />
            <p>TradeVolume (BOSS)</p>
            <p>{Number(token.tradeVolume).toLocaleString()}</p>
          </div>
          <div className="detailBox tradeVolumeUSD">
            <FontAwesomeIcon icon={faDollarSign} />
            <p>Trade Volume (USD)</p>
            <p>${Number(token.tradeVolumeUSD).toLocaleString()}</p>
          </div>
        </div>
      </div>
      <div className="tseactions">
        <a
          href="https://www.dackieswap.xyz/swap?chain=baseGoerli&inputCurrency=0xb4e4127551265ff0f3b6C98e591520E6eEff3F39&outputCurrency=ETH"
          className="action-button trade"
        >
          <div className="action-btn">
            <FontAwesomeIcon icon={faExchangeAlt} /> <span>Trade</span>
          </div>
        </a>
        <a
          href="https://www.dackieswap.xyz/pools?chain=baseGoerli"
          className="action-button farm"
        >
          <div className="action-btn">
            <FontAwesomeIcon icon={faTractor} />
            <span> Farm </span>
          </div>
        </a>
        <a href="https://dackieswap.xyz" className="action-button stake">
          <div className="action-btn">
            <FontAwesomeIcon icon={faHandHoldingUsd} />
            <span>Stake</span>
          </div>
        </a>
      </div>

      <div className="chartContainer">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={PIE_DATA}
              cx="50%"
              cy="50%"
              outerRadius={120}
              fill="#8884d8"
              label
            >
              {PIE_DATA.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend verticalAlign="bottom" height={36} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="footer">
        Powered by{" "}
        <a href="https://dackieswap.xyz" target="_blank" rel="noreferrer">
          DackieSwap Analytics
        </a>
      </div>
    </div>
  );
};

const App = () => (
  <ApolloProvider client={client}>
    <Tokenomics />
  </ApolloProvider>
);

export default App;
