import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { useSelector } from "react-redux";
import "../assets/nftstaking.css";
import { Button, Card, Spinner } from "react-bootstrap";
import cloudBunnyContract from "../artifacts/CloudBunny.sol/CloudBunny.json";
import nftStakingVaultContract from "../artifacts/NFTStakingVaults.sol/NFTStakingVaults.json";
import nftWeightContract from "../artifacts/NFTWeight.sol/NFTWeight.json";
import {
  patronnftContractAddress,
  nftStakingVaultsContractAddress,
  networkDeployedTo,
  nftWeightContractAddress,
} from "../utils/contracts-config";
import networksMap from "../utils/networksMap.json";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AlertModal from "../components/AlertModal";
import Tilt from "react-parallax-tilt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCube,
  faCubes,
  faPercent,
  faCheckSquare,
  faDoorOpen,
  faArrowDown,
  faCoins,
  faInfoCircle,
  faSpinner,
  faArrowUp,
  faCheck,
  faStar,
  faGem,
  faFire,
  faCrown,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function NFTStakingPage() {
  const data = useSelector((state) => state.blockchain.value);
  const [nftStakingContract, setNftStakingContract] = useState(null);
  const [stakedNFTsEth, setStakedNFTsEth] = useState([]);
  const [stakedNFTsBoss, setStakedNFTsBoss] = useState([]);
  const [ethVaultRewards, setEthVaultRewards] = useState([]);
  const [bossVaultRewards, setBossVaultRewards] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [ethVaultData, setEthVaultData] = useState({});
  const [bossVaultData, setBossVaultData] = useState({});
  const [stakedNFTsEthVault, setStakedNFTsEthVault] = useState(0);
  const [stakedNFTsBossVault, setStakedNFTsBossVault] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [weightsEth, setWeightsEth] = useState({});
  const [weightsBoss, setWeightsBoss] = useState({});

  const [userBunnies, setUserBunnies] = useState([]);
  const [isEmergencyWithdrawalEnabled, setIsEmergencyWithdrawalEnabled] =
    useState(false);
  const [totalStakedNFTsEth, setTotalStakedNFTsEth] = useState(0);
  const [totalStakedNFTsBoss, setTotalStakedNFTsBoss] = useState(0);
  const [bossTokenBalance, setBossTokenBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [accruedGasDetails, setAccruedGasDetails] = useState({
    etherSeconds: "",
    etherBalance: "",
    lastUpdated: "",
    gasMode: "",
  });

  useEffect(() => {
    const initializeContractAndFetchNFTs = async () => {
      if (data.network === networksMap[networkDeployedTo]) {
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );
        const nftStaking = new ethers.Contract(
          nftStakingVaultsContractAddress,
          nftStakingVaultContract.abi,
          provider.getSigner()
        );
        setNftStakingContract(nftStaking);

        const fetchVaultData = async (
          vaultFunc,
          setStakedNFTs,
          setVaultData
        ) => {
          const vaultData = await nftStaking[vaultFunc]();
          const stakedNFTs = await nftStaking.getStakedNFTsInVault(
            vaultData.vaultType
          );
          setStakedNFTs(stakedNFTs.toString());

          if (vaultFunc === "ethVault") {
            // Fetching availableEthRewardsDaily for ETH Vault
            const availableRewardsDaily =
              await nftStaking.availableEthRewardsDaily();
            setVaultData({
              vaultType: vaultData.vaultType.toString(),
              maxStakableNFTs: vaultData.maxStakableNFTs.toString(),
              maxNFTsPerVault: vaultData.maxNFTsPerVault.toString(),
              rewardRate: parseFloat(
                ethers.utils.formatEther(availableRewardsDaily)
              ).toFixed(2),
            });
          } else if (vaultFunc === "bossVault") {
            // Fetching dailyRewardPool for Boss Vault
            const dailyRewardPool = await nftStaking.dailyRewardPool();
            setVaultData({
              vaultType: vaultData.vaultType.toString(),
              maxStakableNFTs: vaultData.maxStakableNFTs.toString(),
              maxNFTsPerVault: vaultData.maxNFTsPerVault.toString(),
              rewardRate: parseFloat(
                ethers.utils.formatEther(dailyRewardPool)
              ).toFixed(2),
            });
          } else {
            setVaultData({
              vaultType: vaultData.vaultType.toString(),
              maxStakableNFTs: vaultData.maxStakableNFTs.toString(),
              maxNFTsPerVault: vaultData.maxNFTsPerVault.toString(),
            });
          }
        };

        // Fetch ETH Vault data
        await fetchVaultData(
          "ethVault",
          setStakedNFTsEthVault,
          setEthVaultData
        );

        // Fetch BOSS Vault data
        await fetchVaultData(
          "bossVault",
          setStakedNFTsBossVault,
          setBossVaultData
        );

        // Fetch staked NFTs
        await fetchStakedNFTs(nftStaking);
        getInfo();
      }
    };

    // Start the interval
    const intervalId = setInterval(initializeContractAndFetchNFTs, 60000);

    // Call the function once immediately
    initializeContractAndFetchNFTs();

    // Cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, [data, data.account]);
  

  useEffect(() => {
    const fetchRewards = async () => {
      if (data.network === networksMap[networkDeployedTo]) {
        const ethVaultRewardsPromises = stakedNFTsEth.map((nft) =>
          getEthVaultRewards(nft.id)
        );
        const bossVaultRewardsPromises = stakedNFTsBoss.map((nft) =>
          getBossVaultRewards(nft.id)
        );

        const ethVaultRewards = await Promise.all(ethVaultRewardsPromises);
        const bossVaultRewards = await Promise.all(bossVaultRewardsPromises);

        setEthVaultRewards(ethVaultRewards);
        setBossVaultRewards(bossVaultRewards);
      }
    };

    fetchRewards();
    const intervalId = setInterval(fetchRewards, 16000); // Fetch rewards every 60 seconds

    return () => clearInterval(intervalId); // Clear interval when component unmounts
  }, [data, data.account, stakedNFTsEth, stakedNFTsBoss]);

  const fetchStakedNFTs = async (nftStaking) => {
    try {
      const stakedEth = await nftStaking.getStakedTokensInEthVault(
        data.account
      );
      const stakedBoss = await nftStaking.getStakedTokensInBossVault(
        data.account
      );

      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      const bunny_contract = new ethers.Contract(
        patronnftContractAddress,
        cloudBunnyContract.abi,
        provider
      );

      const fetchMetadata = async (tokenId) => {
        const metadata = await axios.get(
          (
            await bunny_contract.tokenURI(tokenId)
          ).replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
        );

        const attributes = metadata.data.attributes;
        let traitCount = 0;

        for (let attribute of attributes) {
          if (attribute.trait_type === "Trait Count") {
            traitCount = attribute.value;
            break;
          }
        }

        let rarityCategory = "";
        if (traitCount <= 2) {
          rarityCategory = "Common";
        } else if (traitCount <= 4) {
          rarityCategory = "Uncommon";
        } else if (traitCount <= 6) {
          rarityCategory = "Rare";
        } else {
          rarityCategory = "Ultra Rare";
        }

        return {
          id: tokenId.toString(),
          uri: metadata.data.image.replace(
            "ipfs://",
            "https://cloudflare-ipfs.com/ipfs/"
          ),
          rarityCategory,
        };
      };

      const stakedNFTsEthWithMetadata = await Promise.all(
        stakedEth.map(fetchMetadata)
      );
      const stakedNFTsBossWithMetadata = await Promise.all(
        stakedBoss.map(fetchMetadata)
      );

      setStakedNFTsEth(stakedNFTsEthWithMetadata);
      setStakedNFTsBoss(stakedNFTsBossWithMetadata);
    } catch (err) {
      console.error("Failed to fetch staked NFTs:", err);
    }
  };

  useEffect(() => {
    // Assume you are using Metamask
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      nftWeightContractAddress,
      nftWeightContract.abi,
      provider.getSigner()
    );

    async function fetchWeightsEth() {
      const newWeightsEth = { ...weightsEth };

      for (const nft of stakedNFTsEth) {
        try {
          const weight = await contract.getWeight(nft.id);
          newWeightsEth[nft.id] = weight.toString();
        } catch (error) {
          console.error(`Failed to get weight for NFT ${nft.id}: ${error}`);
        }
      }

      setWeightsEth(newWeightsEth);
    }

    async function fetchWeightsBoss() {
      const newWeightsBoss = { ...weightsBoss };

      for (const nft of stakedNFTsBoss) {
        try {
          const weight = await contract.getWeight(nft.id);
          newWeightsBoss[nft.id] = weight.toString();
        } catch (error) {
          console.error(`Failed to get weight for NFT ${nft.id}: ${error}`);
        }
      }

      setWeightsBoss(newWeightsBoss);
    }

    fetchWeightsEth();
    fetchWeightsBoss();
  }, [stakedNFTsEth, stakedNFTsBoss]);

  const getInfo = async () => {
    if (data.network === networksMap[networkDeployedTo]) {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      const bunny_contract = new ethers.Contract(
        patronnftContractAddress,
        cloudBunnyContract.abi,
        provider
      );

      const userBunniesIds = await bunny_contract.walletOfOwner(data.account);

      const _userBunnies = await Promise.all(
        userBunniesIds.map(async (bunnyId) => {
          const metadata = await axios.get(
            (
              await bunny_contract.tokenURI(bunnyId)
            ).replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
          );

          const attributes = metadata.data.attributes;

          let traitCount = 0;

          // Get trait count from attributes
          for (let attribute of attributes) {
            if (attribute.trait_type === "Trait Count") {
              traitCount = attribute.value;
              break;
            }
          }

          // Determine rarity based on trait count
          let rarityCategory = "";
          if (traitCount <= 2) {
            rarityCategory = "Common";
          } else if (traitCount <= 4) {
            rarityCategory = "Uncommon";
          } else if (traitCount <= 6) {
            rarityCategory = "Rare";
          } else {
            rarityCategory = "Ultra Rare";
          }

          return {
            id: bunnyId.toString(),
            uri: metadata.data.image.replace(
              "ipfs://",
              "https://cloudflare-ipfs.com/ipfs/"
            ),
            rarityCategory,
          };
        })
      );

      setUserBunnies(_userBunnies);
    }
  };

  const checkIfApproved = async (tokenId, bunny) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const bunnyContract = new ethers.Contract(
      patronnftContractAddress,
      cloudBunnyContract.abi,
      signer
    );

    const approvedAddress = await bunnyContract.getApproved(tokenId);

    if (
      approvedAddress.toLowerCase() ===
      nftStakingVaultsContractAddress.toLowerCase()
    ) {
      bunny.isApproved = true;
    }
  };

  const approveNFT = async (tokenId, bunny) => {
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      const signer = provider.getSigner();
      const bunnyContract = new ethers.Contract(
        patronnftContractAddress,
        cloudBunnyContract.abi,
        signer
      );

      const tx = await bunnyContract.approve(
        nftStakingVaultsContractAddress,
        tokenId
      );

      await tx.wait();

      checkIfApproved(tokenId, bunny);
      showAlertMessage(`Successfully approved Patron NFT #${tokenId}.`); // Successful Approval Message
    } catch (error) {
      console.error("Failed to approve Patron NFT:", error);
      showAlertMessage(
        `Failed to approve Patron NFT #${tokenId}: ${error.message}`
      ); // Failure Message
    }
  };

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    // Wrap the checks inside Promise.all
    Promise.all(
      userBunnies.map((bunny) => checkIfApproved(bunny.id, bunny))
    ).then(() => setIsLoading(false)); // Once all checks are complete, set isLoading to false
  }, [userBunnies]);

  const claimEthVaultRewards = async (tokenId) => {
    try {
      await nftStakingContract.claimEthVaultRewards([tokenId]);
      showAlertMessage(
        `Successfully claimed ETH rewards for Patron NFT #${tokenId}.`
      );
    } catch (error) {
      console.error("Failed to claim ETH vault rewards:", error);
      showAlertMessage(
        `Failed to claim ETH vault rewards for NFT ${tokenId}: ${error.message}`
      );
    }
  };

  const claimBossVaultRewards = async (tokenId) => {
    try {
      await nftStakingContract.claimBossVaultRewards([tokenId]);
      showAlertMessage(
        `Successfully claimed $PAT token rewards for Patron NFT #${tokenId}.`
      );
    } catch (error) {
      console.error("Failed to claim Pat vault rewards:", error);
      showAlertMessage(
        `Failed to claim Pat vault rewards for NFT ${tokenId}: ${error.message}`
      );
    }
  };

  const getEthVaultRewards = async (tokenId) => {
    try {
      const rewards = await nftStakingContract.getEthVaultRewards([tokenId]);
      return rewards[0];
    } catch (error) {
      console.error("Failed to get ETH vault rewards:", error);
      return ethers.BigNumber.from(0);
    }
  };

  const getBossVaultRewards = async (tokenId) => {
    try {
      const rewards = await nftStakingContract.getBossVaultRewards([tokenId]);
      return rewards[0];
    } catch (error) {
      console.error("Failed to get Pat vault rewards:", error);
      return ethers.BigNumber.from(0);
    }
  };

  const stakeToEthVault = async (tokenId) => {
    try {
      await nftStakingContract.stakeToEthVault([tokenId]);
      showAlertMessage(`Successfully staked Patron #${tokenId} to ETH vault.`);
    } catch (error) {
      console.error("Failed to stake to ETH vault:", error);
      showAlertMessage(
        `Failed to stake Patron #${tokenId} to ETH vault: Staking these NFT will exceed the maximum stakable NFTs limit or Max NFTs per Vault limit, Unstake some NFTs or Move to Pat Vault",`
      );
    }
  };

  const stakeToBossVault = async (tokenId) => {
    try {
      await nftStakingContract.stakeToBossVault([tokenId]);
      showAlertMessage(
        `Successfully staked Patron NFT #${tokenId} to Pat vault.`
      );
    } catch (error) {
      console.error("Failed to stake to Pat vault:", error);
      showAlertMessage(
        `Failed to stake Patron #${tokenId} to Pat vault: Staking these NFT will exceed the maximum stakable NFTs limit Max NFTs per Vault limit, Unstake some NFTs or Move to ETH Vault",`
      );
    }
  };

  const unstakeFromEthVault = async (tokenId) => {
    try {
      await nftStakingContract.unstakeFromEthVault([tokenId]);
      showAlertMessage(
        `Successfully unstaked Patron NFT #${tokenId} from ETH vault.`
      );
    } catch (error) {
      console.error("Failed to unstake from ETH vault:", error);
      showAlertMessage(
        `Failed to unstake NFT ${tokenId} from ETH vault: ${error.message}`
      );
    }
  };

  const unstakeFromBossVault = async (tokenId) => {
    try {
      await nftStakingContract.unstakeFromBossVault([tokenId]);
      showAlertMessage(
        `Successfully unstaked Patron NFT #${tokenId} from Pat vault.`
      );
    } catch (error) {
      console.error("Failed to unstake from Patt vault:", error);
      showAlertMessage(
        `Failed to unstake NFT ${tokenId} from Pat vault: ${error.message}`
      );
    }
  };

  const emergencyWithdrawFromEthVault = async (tokenId) => {
    try {
      await nftStakingContract.emergencyWithdrawFromEthVault(tokenId);
      showAlertMessage(
        `Successfully performed emergency withdrawal of Patron NFT #${tokenId} from ETH vault.`
      );
    } catch (error) {
      console.error(
        "Failed to perform emergency withdrawal from ETH vault:",
        error
      );
      showAlertMessage(
        `Failed to perform emergency withdrawal of NFT ${tokenId} from ETH vault: ${error.message}`
      );
    }
  };

  const emergencyWithdrawFromBossVault = async (tokenId) => {
    try {
      await nftStakingContract.emergencyWithdrawFromBossVault(tokenId);
      showAlertMessage(
        `Successfully performed emergency withdrawal of Patron NFT #${tokenId} from Pat vault.`
      );
    } catch (error) {
      console.error(
        "Failed to perform emergency withdrawal from Pat vault:",
        error
      );
      showAlertMessage(
        `Failed to perform emergency withdrawal of NFT ${tokenId} from Pat vault: ${error.message}`
      );
    }
  };

  const approveNFTHandler = async (id, nft) => {
    setIsLoadingBtn((prevState) => ({ ...prevState, [id]: { approve: true } }));
    try {
      await approveNFT(id, nft);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { approve: false },
    }));
  };

  const stakeToEthVaultHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ethStake: true },
    }));
    try {
      await stakeToEthVault(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ethStake: false },
    }));
  };

  const stakeToBossVaultHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { bossStake: true },
    }));
    try {
      await stakeToBossVault(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { bossStake: false },
    }));
  };

  const unstakeFromEthVaultHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], unstake: true },
    }));
    try {
      await unstakeFromEthVault(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], unstake: false },
    }));
  };

  const claimEthVaultRewardsHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], claim: true },
    }));
    try {
      await claimEthVaultRewards(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], claim: false },
    }));
  };

  const emergencyWithdrawFromEthVaultHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], exit: true },
    }));
    try {
      await emergencyWithdrawFromEthVault(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], exit: false },
    }));
  };

  const unstakeFromBossVaultHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], bossUnstake: true },
    }));
    try {
      await unstakeFromBossVault(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], bossUnstake: false },
    }));
  };

  const claimBossVaultRewardsHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], bossClaim: true },
    }));
    try {
      await claimBossVaultRewards(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], bossClaim: false },
    }));
  };

  const emergencyWithdrawFromBossVaultHandler = async (id) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], bossExit: true },
    }));
    try {
      await emergencyWithdrawFromBossVault(id);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], bossExit: false },
    }));
  };

  useEffect(() => {
    const checkEmergencyWithdrawalStatus = async () => {
      if (nftStakingContract) {
        const status = await nftStakingContract.emergencyWithdrawalEnabled();
        setIsEmergencyWithdrawalEnabled(status);
      }
    };

    checkEmergencyWithdrawalStatus();
  }, [nftStakingContract]);

  useEffect(() => {
    const fetchTotalStakedNFTs = async () => {
      if (nftStakingContract) {
        const totalStakedEth = await nftStakingContract.getTotalStakedNFTs(1); // Assuming '1' is the vaultType for ETH
        const totalStakedBoss = await nftStakingContract.getTotalStakedNFTs(2); // Assuming '2' is the vaultType for BOSS
        setTotalStakedNFTsEth(totalStakedEth.toString()); // Convert BigNumber to string
        setTotalStakedNFTsBoss(totalStakedBoss.toString()); // Convert BigNumber to string
      }
    };

    fetchTotalStakedNFTs();
  }, [nftStakingContract]);

  useEffect(() => {
    const fetchBossTokenBalance = async () => {
      if (nftStakingContract) {
        const balance = await nftStakingContract.getContractBossTokenBalance();
        const formattedBalance = ethers.utils.formatUnits(balance, "ether");
        setBossTokenBalance(formatNumber(formattedBalance));
      }
    };

    fetchBossTokenBalance();
  }, [nftStakingContract]);

  useEffect(() => {
    const fetchEthBalance = async () => {
      if (nftStakingContract) {
        const balance = await nftStakingContract.getContractEthBalance();
        setEthBalance(formatNumber(ethers.utils.formatUnits(balance, "ether"))); // Convert and format the balance
      }
    };

    fetchEthBalance();
  }, [nftStakingContract]);

  const getAccruedGasDetails = async () => {
    if (nftStakingContract) {
      try {
        const gasDetails = await nftStakingContract.getAccruedGasDetails();
        setAccruedGasDetails({
          etherSeconds: gasDetails.etherSeconds.toString(),
          etherBalance: ethers.utils.formatEther(gasDetails.etherBalance),
          lastUpdated: new Date(gasDetails.lastUpdated * 1000).toLocaleString(),
          gasMode: gasDetails.gasMode,
        });
      } catch (error) {
        console.error("Failed to fetch accrued gas details:", error);
      }
    }
  };

  useEffect(() => {
    getAccruedGasDetails(); // Call this function to fetch the data when the component mounts
  }, [nftStakingContract]);

  // Function to format the number with commas and limit decimal places
  function formatNumber(number) {
    const MAX_DECIMALS = 2; // Set the maximum number of decimal places
    const parts = number.split(".");
    const integerPart = parseInt(parts[0]).toLocaleString(); // Format the integer part with commas
    const decimalPart = parts[1] ? parts[1].slice(0, MAX_DECIMALS) : ""; // Limit the decimal part to MAX_DECIMALS
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }

  const transferNFTHandler = async (tokenId, fromVaultType, toVaultType) => {
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [tokenId]: { ...prevState[tokenId], transfer: true },
    }));
    try {
      // Call the smart contract function to transfer the NFT
      await nftStakingContract.transferStakedNFTBetweenVaults(
        tokenId,
        fromVaultType,
        toVaultType
      );
      showAlertMessage(`Successfully transferred NFT #${tokenId}.`);
    } catch (error) {
      console.error("Failed to transfer NFT:", error);
      showAlertMessage(`Failed to transfer NFT #${tokenId}: ${error.message}`);
    }
    setIsLoadingBtn((prevState) => ({
      ...prevState,
      [tokenId]: { ...prevState[tokenId], transfer: false },
    }));
  };

  return (
    <div className="SApp">
      <NavBar />
      <AlertModal
        isOpen={isAlertOpen}
        message={alertMessage}
        onClose={() => setIsAlertOpen(false)}
      />

      {/* <h1 className="bosses-vaults-header">The El's Vaults</h1> */}

      <section>
        <div className="accrued-gas-details">
          <h3>Gas & Eth Yield Details</h3>

          <div className="info-box">
            <h4>Ether Balance</h4>
            <p>{accruedGasDetails.etherBalance} ETH</p>
          </div>
          <div className="info-box">
            <h4>ETH Yield</h4>
            <p>Automatic Yield</p>
          </div>
        </div>
        <h2 className="owned-nfts-header">Your Owned NFTs</h2>

        <div className="ownedNFTsContainer d-flex flex-wrap justify-content-center">
          {userBunnies.length > 0 ? (
            userBunnies.map((nft, index) => (
              <Card
                key={index}
                className="ownedNFTCard m-2"
                style={{ width: "18rem" }}
              >
                <div className="imageContainer">
                  <Tilt options={{ max: 25 }}>
                    <Card.Img
                      variant="top"
                      src={nft.uri}
                      alt={`NFT ${nft.id}`}
                    />

                    <div className="rarityBadge">
                      {nft.rarityCategory === "Common" && (
                        <>
                          <FontAwesomeIcon icon={faStar} /> Common
                        </>
                      )}
                      {nft.rarityCategory === "Uncommon" && (
                        <>
                          <FontAwesomeIcon icon={faGem} /> Uncommon
                        </>
                      )}
                      {nft.rarityCategory === "Rare" && (
                        <>
                          <FontAwesomeIcon icon={faFire} /> Rare
                        </>
                      )}
                      {nft.rarityCategory === "Ultra Rare" && (
                        <>
                          <FontAwesomeIcon icon={faCrown} /> Ultra Rare
                        </>
                      )}
                    </div>
                  </Tilt>
                </div>

                <Card.Body>
                  <Card.Title>PATRON #{nft.id}</Card.Title>
                  {nft.isApproved ? (
                    <>
                      <Button
                        variant="primary"
                        className="mr-2"
                        disabled={isLoadingBtn[nft.id]?.ethStake}
                        onClick={() => stakeToEthVaultHandler(nft.id)}
                      >
                        {isLoadingBtn[nft.id]?.ethStake ? (
                          <>
                            Staking... <FontAwesomeIcon icon={faSpinner} spin />
                          </>
                        ) : (
                          <>
                            Stake to $ETH Vault{" "}
                            <FontAwesomeIcon icon={faArrowUp} />
                          </>
                        )}
                      </Button>
                      <Button
                        variant="secondary"
                        disabled={isLoadingBtn[nft.id]?.bossStake}
                        onClick={() => stakeToBossVaultHandler(nft.id)}
                      >
                        {isLoadingBtn[nft.id]?.bossStake ? (
                          <>
                            Staking... <FontAwesomeIcon icon={faSpinner} spin />
                          </>
                        ) : (
                          <>
                            Stake to $PAT Vault{" "}
                            <FontAwesomeIcon icon={faArrowUp} />
                          </>
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Card.Text>
                        Click the button below to approve this NFT for staking.
                      </Card.Text>
                      <Button
                        variant="warning"
                        disabled={isLoadingBtn[nft.id]?.approve}
                        onClick={() => approveNFTHandler(nft.id, nft)}
                      >
                        {isLoadingBtn[nft.id]?.approve ? (
                          <>
                            Approving...{" "}
                            <FontAwesomeIcon icon={faSpinner} spin />
                          </>
                        ) : (
                          <>
                            Approve NFT <FontAwesomeIcon icon={faCheck} />
                          </>
                        )}
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
            ))
          ) : (
            <div className="empty-state">
              <h3>You have no NFTs in your collection.</h3>
              <p>Mint Some NFTs or Buy Some NFTs!</p>
              <div className="btn-container">
                <a
                  href="https://www.externalsite.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="empty-btn">Buy NFT</button>
                </a>
                <Link to="/mint-page">
                  <button className="empty-btn">Mint NFT</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>

      <section>
        <h2 className="owned-nfts-header">Staked EL's in ETH Vault</h2>
        <div className="vault-info-container grid-container">
          <div className="vault-item">
            <FontAwesomeIcon icon={faPercent} className="vault-icon" />
            <h3>Emission:</h3>
            <p>
              {ethVaultData.rewardRate}{" "}
              <FontAwesomeIcon icon={faEthereum} className="ethicon" />{" "}
              <span className="currency">$ETH</span>
            </p>
          </div>
          <div className="vault-item">
            <FontAwesomeIcon icon={faCube} className="vault-icon" />
            <h3>Max Stakable NFTs:</h3>
            <p>
              {stakedNFTsEthVault}/{ethVaultData.maxStakableNFTs}
            </p>
          </div>
          <div className="vault-item">
            <FontAwesomeIcon icon={faCubes} className="vault-icon" />
            <h3>Max NFTs per Vault:</h3>
            <p>
              {totalStakedNFTsEth}/{ethVaultData.maxNFTsPerVault}
            </p>{" "}
            {/* For ETH Vault */}
          </div>
          <div className="vault-item">
            <FontAwesomeIcon icon={faCheckSquare} className="vault-icon" />
            <h3>ETH Pool:</h3>
            <p>{ethBalance} $ETH</p>{" "}
            {/* Now shows the Ethereum balance in a more readable format */}
          </div>
        </div>

        <div className="stakedNFTsContainer d-flex flex-wrap justify-content-center position-relative">
          <a
            href="https://docs.el-patron.xyz/els-guides/how-to-stake-patron-nft"
            target="_blank"
            rel="noopener noreferrer"
            className="sophisticated-btn"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            How to stake
          </a>
          {stakedNFTsEth.length > 0 ? (
            stakedNFTsEth.map((nft, index) => (
              <Card
                key={index}
                className="stakedNFTCard m-2"
                style={{ width: "18rem" }}
              >
                <Tilt options={{ max: 25 }}>
                  <div className="imageContainer">
                    <Card.Img
                      variant="top"
                      src={nft.uri}
                      alt={`NFT ${nft.id}`}
                    />
                    <div className="rarityBadge">
                      {nft.rarityCategory === "Common" && (
                        <>
                          <FontAwesomeIcon icon={faStar} /> Common
                        </>
                      )}
                      {nft.rarityCategory === "Uncommon" && (
                        <>
                          <FontAwesomeIcon icon={faGem} /> Uncommon
                        </>
                      )}
                      {nft.rarityCategory === "Rare" && (
                        <>
                          <FontAwesomeIcon icon={faFire} /> Rare
                        </>
                      )}
                      {nft.rarityCategory === "Ultra Rare" && (
                        <>
                          <FontAwesomeIcon icon={faCrown} /> Ultra Rare
                        </>
                      )}
                    </div>
                    <div
                      className="Rcontainer"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                      }}
                    >
                      <p className="Rtext">PATRON #{nft.id}</p>
                    </div>

                    <div
                      className="Rtext-value"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      {ethVaultRewards[index] ? (
                        // Convert to a number and round to a specific number of decimal places
                        parseFloat(
                          ethers.utils.formatEther(ethVaultRewards[index])
                        ).toFixed(5)
                      ) : (
                        <Spinner
                          animation="border"
                          className="Spinner"
                          variant="info"
                        />
                      )}
                    </div>

                    <div
                      className="weight"
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "5px",
                      }}
                      title="Weight indicates the power or influence this NFT has in the reward system. Higher weight equals more rewards generated by the nft"
                    >
                      {weightsEth[nft.id] && (
                        <>
                          <FontAwesomeIcon icon={faWeightHanging} />{" "}
                          {weightsEth[nft.id]}x
                        </>
                      )}
                    </div>
                  </div>
                </Tilt>

                <Card.Body>
                  <Button
                    variant="danger"
                    className="mr-2"
                    disabled={isLoadingBtn[nft.id]?.unstake}
                    onClick={() => unstakeFromEthVaultHandler(nft.id)}
                  >
                    {isLoadingBtn[nft.id]?.unstake ? (
                      <>
                        Unstaking... <FontAwesomeIcon icon={faSpinner} spin />
                      </>
                    ) : (
                      <>
                        Unstake <FontAwesomeIcon icon={faArrowDown} />
                      </>
                    )}
                  </Button>
                  <Button
                    variant="success"
                    disabled={isLoadingBtn[nft.id]?.claim}
                    onClick={() => claimEthVaultRewardsHandler(nft.id)}
                  >
                    {isLoadingBtn[nft.id]?.claim ? (
                      <>
                        Claiming... <FontAwesomeIcon icon={faSpinner} spin />
                      </>
                    ) : (
                      <>
                        Claim Rewards <FontAwesomeIcon icon={faCoins} />
                      </>
                    )}
                  </Button>
                  <Button
                    variant="warning"
                    className="mt-2"
                    disabled={isLoadingBtn[nft.id]?.transfer}
                    onClick={() => transferNFTHandler(nft.id, 1, 2)} // Assuming 1 is ETH Vault and 2 is BOSS Vault
                  >
                    {isLoadingBtn[nft.id]?.transfer ? (
                      <>
                        Transferring...{" "}
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </>
                    ) : (
                      <>
                        Transfer to $PAT Vault{" "}
                        <FontAwesomeIcon icon={faArrowUp} />
                      </>
                    )}
                  </Button>

                  {isEmergencyWithdrawalEnabled && (
                    <Button
                      variant="danger"
                      className="exit-button mt-2"
                      disabled={isLoadingBtn[nft.id]?.exit}
                      onClick={() =>
                        emergencyWithdrawFromEthVaultHandler(nft.id)
                      }
                    >
                      {isLoadingBtn[nft.id]?.exit ? (
                        <>
                          Exiting... <FontAwesomeIcon icon={faSpinner} spin />
                        </>
                      ) : (
                        <>
                          Exit <FontAwesomeIcon icon={faDoorOpen} />
                        </>
                      )}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            ))
          ) : (
            <div className="empty-state">
              <h3>You have no NFTs staked in the ETH vault.</h3>
              <p>Stake your NFTs to earn $ETH rewards!</p>
              <div className="btn-container">
                <a
                  href="https://www.externalsite.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="empty-btn">Buy NFT</button>
                </a>
                <Link to="/mint-page">
                  <button className="empty-btn">Mint NFT</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>

      <section>
        <h2 className="owned-nfts-header">Staked EL's in $PAT Vault</h2>
        <div className="vault-info-container grid-container">
          <div className="vault-item">
            <FontAwesomeIcon icon={faPercent} className="vault-icon" />
            <h3>$PAT/DAY:</h3>
            <p>
              {bossVaultData.rewardRate} <span className="currency">$PAT</span>
            </p>
          </div>
          <div className="vault-item">
            <FontAwesomeIcon icon={faCube} className="vault-icon" />
            <h3>Max Stakable NFTs:</h3>
            <p>
              {stakedNFTsBossVault}/{bossVaultData.maxStakableNFTs}
            </p>
          </div>
          <div className="vault-item">
            <FontAwesomeIcon icon={faCubes} className="vault-icon" />
            <h3>Max NFTs per Vault:</h3>
            <p>
              {totalStakedNFTsBoss}/{bossVaultData.maxNFTsPerVault}
            </p>{" "}
            {/* For BOSS Vault */}
          </div>
          <div className="vault-item">
            <FontAwesomeIcon icon={faCheckSquare} className="vault-icon" />
            <h3>$PAT Pool:</h3>
            <p>{bossTokenBalance} $PAT</p>
          </div>
        </div>

        <div className="stakedNFTsContainer d-flex flex-wrap justify-content-center position-relative">
          <a
            href="https://docs.el-patron.xyz/els-guides/how-to-stake-patron-nft"
            target="_blank"
            rel="noopener noreferrer"
            className="sophisticated-btn"
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            How to stake
          </a>

          {stakedNFTsBoss.length > 0 ? (
            stakedNFTsBoss.map((nft, index) => (
              <Card
                key={index}
                className="stakedNFTCard m-2"
                style={{ width: "18rem" }}
              >
                <Tilt options={{ max: 25 }}>
                  <div className="imageContainer">
                    <Card.Img
                      variant="top"
                      src={nft.uri}
                      alt={`NFT ${nft.id}`}
                    />
                    <div className="rarityBadge">
                      {nft.rarityCategory === "Common" && (
                        <>
                          <FontAwesomeIcon icon={faStar} /> Common
                        </>
                      )}
                      {nft.rarityCategory === "Uncommon" && (
                        <>
                          <FontAwesomeIcon icon={faGem} /> Uncommon
                        </>
                      )}
                      {nft.rarityCategory === "Rare" && (
                        <>
                          <FontAwesomeIcon icon={faFire} /> Rare
                        </>
                      )}
                      {nft.rarityCategory === "Ultra Rare" && (
                        <>
                          <FontAwesomeIcon icon={faCrown} /> Ultra Rare
                        </>
                      )}
                    </div>
                    <div
                      className="Rcontainer"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                      }}
                    >
                      <p className="Rtext">PATRON #{nft.id}</p>
                    </div>
                    <div
                      className="weight"
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        left: "5px",
                      }}
                      title="Weight indicates the power or influence this NFT has in the reward system. Higher weight equals more rewards generated by the nft"
                    >
                      {weightsBoss[nft.id] && (
                        <>
                          <FontAwesomeIcon icon={faWeightHanging} />{" "}
                          {weightsBoss[nft.id]}x
                        </>
                      )}
                    </div>
                    <div
                      className="Rtext-value"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      {bossVaultRewards[index] ? (
                        // Convert to a number and round to a specific number of decimal places
                        parseFloat(
                          ethers.utils.formatEther(bossVaultRewards[index])
                        ).toFixed(2)
                      ) : (
                        <Spinner animation="border" variant="info" />
                      )}
                    </div>
                  </div>
                </Tilt>

                <Card.Body>
                  <Button
                    variant="danger"
                    className="mr-2"
                    disabled={isLoadingBtn[nft.id]?.bossUnstake}
                    onClick={() => unstakeFromBossVaultHandler(nft.id)}
                  >
                    {isLoadingBtn[nft.id]?.bossUnstake ? (
                      <>
                        Unstaking... <FontAwesomeIcon icon={faSpinner} spin />
                      </>
                    ) : (
                      <>
                        Unstake <FontAwesomeIcon icon={faArrowDown} />
                      </>
                    )}
                  </Button>
                  <Button
                    variant="success"
                    disabled={isLoadingBtn[nft.id]?.bossClaim}
                    onClick={() => claimBossVaultRewardsHandler(nft.id)}
                  >
                    {isLoadingBtn[nft.id]?.bossClaim ? (
                      <>
                        Claiming... <FontAwesomeIcon icon={faSpinner} spin />
                      </>
                    ) : (
                      <>
                        Claim Rewards <FontAwesomeIcon icon={faCoins} />
                      </>
                    )}
                  </Button>
                  <Button
                    variant="warning"
                    className="mt-2"
                    disabled={isLoadingBtn[nft.id]?.transfer}
                    onClick={() => transferNFTHandler(nft.id, 2, 1)} // Assuming 2 is BOSS Vault and 1 is ETH Vault
                  >
                    {isLoadingBtn[nft.id]?.transfer ? (
                      <>
                        Transferring...{" "}
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </>
                    ) : (
                      <>
                        Transfer to ETH Vault{" "}
                        <FontAwesomeIcon icon={faArrowUp} />
                      </>
                    )}
                  </Button>

                  {isEmergencyWithdrawalEnabled && (
                    <Button
                      variant="danger"
                      className="exit-button mt-2"
                      disabled={isLoadingBtn[nft.id]?.bossExit}
                      onClick={() =>
                        emergencyWithdrawFromBossVaultHandler(nft.id)
                      }
                    >
                      {isLoadingBtn[nft.id]?.bossExit ? (
                        <>
                          Exiting... <FontAwesomeIcon icon={faSpinner} spin />
                        </>
                      ) : (
                        <>
                          Exit <FontAwesomeIcon icon={faDoorOpen} />
                        </>
                      )}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            ))
          ) : (
            <div className="empty-state">
              <h3>You have no NFTs staked in the $PAT vault.</h3>
              <p>Stake your NFTs to earn $PAT Token rewards!</p>
              <div className="btn-container">
                <a
                  href="https://www.externalsite.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="empty-btn">Buy NFT</button>
                </a>
                <Link to="/mint-page">
                  <button className="empty-btn">Mint NFT</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default NFTStakingPage;
