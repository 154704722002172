import React, { useState, useEffect } from "react";

import "../assets/mintpage.css";
import cloudBunnyImage from "../assets/img/heroimgc.png";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import axios from "axios";
import { Modal, InputGroup, FormControl } from "react-bootstrap";

import cloudBunnyContract from "../artifacts/CloudBunny.sol/CloudBunny.json";
import {
  patronnftContractAddress,
  networkDeployedTo,
} from "../utils/contracts-config";
import networksMap from "../utils/networksMap.json";
import AlertModal from "../components/AlertModal";

import Tilt from "react-parallax-tilt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfinity,
  faTag,
  faTags,
  faUser,
  faUsers,
  faCoins,
  faPlus,
  faMinus,
  faStar,
  faGem,
  faFire,
  faCrown,
  faListAlt,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";

function CloudBunnyPage() {
  const data = useSelector((state) => state.blockchain.value);

  const [mintAmount, setMintAmount] = useState(1);
  const [userBunnies, setUserBunnies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBunny, setSelectedBunny] = useState(null);
  const [selectedBunnyMetadata, setSelectedBunnyMetadata] = useState(null);
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const [ethBalance, setEthBalance] = useState(null);

  const [info, setInfo] = useState({
    maxSupply: 10000,
    presalePrice: 0.008,
    publicPrice: 0.01,
    publicAmt: 5,
    presaleAmt: 7,
  });
  const [loading, setLoading] = useState(false);

  const [totalMinted, setTotalMinted] = useState(0);
  const [isPresaleActive, setIsPresaleActive] = useState(false);
  const [isPublicSaleActive, setIsPublicSaleActive] = useState(false);

  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState("");

  const showAlertModal = (message) => {
    setAlertModalMessage(message);
    setAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setAlertModalOpen(false);
  };

  const getInfo = async () => {
    if (data.network === networksMap[networkDeployedTo]) {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      const bunny_contract = new ethers.Contract(
        patronnftContractAddress,
        cloudBunnyContract.abi,
        provider
      );

      const maxSupply = await bunny_contract.maxSupply();
      const presalePrice = Number(
        ethers.utils.formatUnits(await bunny_contract.presalePrice(), "ether")
      );
      const publicPrice = Number(
        ethers.utils.formatUnits(await bunny_contract.publicPrice(), "ether")
      );
      const publicAmt = await bunny_contract.publicAmt();
      const presaleAmt = await bunny_contract.presaleAmt();

      const totalSupply = await bunny_contract.totalSupply();
      const isPresale = await bunny_contract.isPresale();
      const isPublicSale = await bunny_contract.isPublicSale();

      setTotalMinted(Number(totalSupply));
      setIsPresaleActive(isPresale);
      setIsPublicSaleActive(isPublicSale);

      setInfo({
        maxSupply: Number(maxSupply),
        presalePrice: presalePrice,
        publicPrice: publicPrice,
        publicAmt: Number(publicAmt),
        presaleAmt: Number(presaleAmt),
      });

      const launchTime = await bunny_contract.launchTime();

      let interval = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const diff = launchTime - now + 24 * 60 * 60; // launchTime is assumed to be in seconds.
        if (diff <= 0) {
          clearInterval(interval);
          setTimer("Public Sale Started");
        } else {
          const hours = Math.floor(diff / 3600);
          const minutes = Math.floor(diff / 60) % 60;
          const seconds = diff % 60;
          setTimer(`${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);

      const userBunniesIds = await bunny_contract.walletOfOwner(data.account);

      const _userBunnies = await Promise.all(
        userBunniesIds.map(async (bunnyId) => {
          const metadata = await axios.get(
            (
              await bunny_contract.tokenURI(bunnyId)
            ).replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
          );

          const attributes = metadata.data.attributes;

          let traitCount = 0;

          // Get trait count from attributes
          for (let attribute of attributes) {
            if (attribute.trait_type === "Trait Count") {
              traitCount = attribute.value;
              break;
            }
          }

          // Determine rarity based on trait count
          let rarityCategory = "";
          if (traitCount <= 2) {
            rarityCategory = "Common";
          } else if (traitCount <= 4) {
            rarityCategory = "Uncommon";
          } else if (traitCount <= 6) {
            rarityCategory = "Rare";
          } else {
            rarityCategory = "Ultra Rare";
          }

          return {
            id: bunnyId.toString(),
            uri: metadata.data.image.replace(
              "ipfs://",
              "https://cloudflare-ipfs.com/ipfs/"
            ),
            rarityCategory,
          };
        })
      );

      setUserBunnies(_userBunnies);
    }
  };

  useEffect(() => {
    getInfo();
  }, [data.network, networkDeployedTo]);

  const mint = async (isPresale) => {
    if (!data.account) {
      showAlertModal("Please connect your wallet first.");
      return;
    }

    if (data.network === networksMap[networkDeployedTo]) {
      try {
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );
        const signer = provider.getSigner();
        const bunny_contract = new ethers.Contract(
          patronnftContractAddress,
          cloudBunnyContract.abi,
          signer
        );

        const presaleActive = await bunny_contract.isPresale();
        const publicSaleActive = await bunny_contract.isPublicSale();

        if (isPresale && !presaleActive) {
          showAlertModal("The presale has not started yet.");
          setLoading(false);
          return;
        }

        if (!isPresale && !publicSaleActive) {
          showAlertModal("The public sale has not started yet.");
          setLoading(false);
          return;
        }

        const maxPresaleMint = await bunny_contract.presaleAmt();
        const maxPublicMint = await bunny_contract.publicAmt();
        const maxMint = isPresale ? maxPresaleMint : maxPublicMint;

        if (mintAmount > maxMint) {
          showAlertModal(
            `You cannot mint more than ${maxMint} patrons at once.`
          );
          setLoading(false);
          return;
        }

        const whitelisted = await bunny_contract.isWhitelisted(data.account);

        if (isPresale && !whitelisted) {
          showAlertModal("You are not whitelisted for the presale.");
          setLoading(false);
          return;
        }

        if (isPresale) {
          const mintPrice = ethers.utils.parseEther(
            String(info.presalePrice * mintAmount)
          );
          const mint_tx = await bunny_contract.WLMint(mintAmount, {
            value: mintPrice,
          });
          await mint_tx.wait();
          showAlertModal(`Minted ${mintAmount} Patron successfully!`);
        } else {
          const mintPrice = ethers.utils.parseEther(
            String(info.publicPrice * mintAmount)
          );
          const mint_tx = await bunny_contract.PMint(mintAmount, {
            value: mintPrice,
          });
          await mint_tx.wait();
          showAlertModal(`Minted ${mintAmount} Patron successfully!`);
        }

        getInfo();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Minting failed: ", err);

        // Check if the error message includes "Exceeds public mint allowance!"
        if (err.message.includes("Exceeds public mint allowance!")) {
          showAlertModal(
            "Minting failed: public/Wl has been exceeded Max Mintable NFTs."
          );
        } else {
          showAlertModal(
            "Minting Canceled! Please confirm transaction on metamask to complete transaction."
          );
        }
      }
    } else {
      showAlertModal(`Please connect to the ${networkDeployedTo} network.`);
    }
  };

  const handleModal = async (bunny) => {
    if (bunny) {
      try {
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );
        const bunnyContract = new ethers.Contract(
          patronnftContractAddress,
          cloudBunnyContract.abi,
          provider
        );

        const metadata = await axios.get(
          (
            await bunnyContract.tokenURI(bunny.id)
          ).replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
        );
        setSelectedBunnyMetadata(metadata.data);
      } catch (error) {
        console.error("Failed to fetch metadata:", error);
      }
    } else {
      setSelectedBunnyMetadata(null);
    }
    setSelectedBunny(bunny);
    setShowModal(!showModal);
  };

  useEffect(() => {
    getInfo();
    setSelectedBunnyMetadata(null);
  }, [data, data.account]);

  const increment = () => {
    if (mintAmount < 4) {
      setMintAmount((prevAmount) => prevAmount + 1);
    }
  };

  const decrement = () => {
    if (mintAmount > 1) {
      setMintAmount((prevAmount) => prevAmount - 1);
    }
  };

  const mintCost = isPresaleActive
    ? info.presalePrice * mintAmount
    : info.publicPrice * mintAmount;

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );

      const fetchEthBalance = async () => {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const balance = await provider.getBalance(accounts[0]);
        setEthBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(4));
      };

      fetchEthBalance();

      window.ethereum.on("accountsChanged", fetchEthBalance);

      // Clean up the event listener on component unmount
      return () =>
        window.ethereum.removeListener("accountsChanged", fetchEthBalance);
    }
  }, []);

  const nftDescription =
    "Welcome to the Cartel, where an exclusive collection of 10,000 unique Patrons is ready to take over Blast. When the boss is in town, a meeting at the Cartel is a must-attend event. You won't want to miss out on the deal 🤝. Join us!";

  return (
    <div className="MApp">
      <NavBar />
      <div className="container">
        {/* <h1 className="bosses-nft-header">EL PATRON NFT</h1> */}
        <div class="mdcontainer">
          <div class="boss-row">
            <div class="boss-mint-section">
              <h2>Mint Your Patrons</h2>
              <div class="boss-mint-controls">
                <div class="boss-counter-container">
                  <div class="boss-decrement">
                    <button onClick={decrement}>
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                  </div>
                  <input
                    className="boss-mint-input"
                    type="number"
                    min="1"
                    max="4"
                    value={mintAmount}
                    onChange={(e) => setMintAmount(e.target.value)}
                  />
                  <div class="boss-increment">
                    <button onClick={increment}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>

                <div class="boss-cost-balance">
                  <div class="boss-cost">
                    <FontAwesomeIcon icon={faEthereum} className="eicon" />
                    <span>Cost: {mintCost} $ETH</span>
                  </div>
                  <div class="boss-balance">
                    <FontAwesomeIcon icon={faEthereum} className="eicon" />
                    <span>Balance: {ethBalance} $ETH</span>
                  </div>
                </div>

                <button
                  className="boss-mint-button"
                  onClick={() => mint(true)}
                  disabled={
                    loading || !isPresaleActive || mintCost > ethBalance
                  }
                >
                  {loading
                    ? "Minting..."
                    : `Presale Mint - ${info.presalePrice} $ETH`}
                </button>

                <button
                  className="boss-mint-button"
                  onClick={() => mint(false)}
                  disabled={
                    loading || !isPublicSaleActive || mintCost > ethBalance
                  }
                >
                  {loading
                    ? "Minting..."
                    : `Public Mint - ${info.publicPrice} $ETH`}
                </button>
              </div>
            </div>
            <div class="boss-nft-description">
              <Tilt options={{ max: 25 }}>
                <img src={cloudBunnyImage} alt="Cloud Bunny" />
              </Tilt>
              <p>{nftDescription}</p>
            </div>
          </div>
        </div>

        <div className="infoSection">
          <div className="infoItem Ptimer">
            <h2 className="infoLabel">Public Mint Countdown</h2>
            <p className="timer">{timer ? timer : "Launched"}</p>
          </div>

          <div className="infoGrid">
            <div className="infoItem">
              <FontAwesomeIcon icon={faInfinity} className="icon" />
              <h3 className="infoLabel">Max Supply</h3>
              <p className="infoValue">{info.maxSupply}</p>
            </div>
            <div className="infoItem">
              <FontAwesomeIcon icon={faTag} className="icon" />
              <h3 className="infoLabel">Presale Price</h3>
              <p className="infoValue">{info.presalePrice} ETH</p>
            </div>
            <div className="infoItem">
              <FontAwesomeIcon icon={faTags} className="icon" />
              <h3 className="infoLabel">Public Price</h3>
              <p className="infoValue">{info.publicPrice} ETH</p>
            </div>
            <div className="infoItem">
              <FontAwesomeIcon icon={faUser} className="icon" />
              <h3 className="infoLabel">Presale Limit</h3>
              <p className="infoValue">{info.presaleAmt}</p>
            </div>
            <div className="infoItem">
              <FontAwesomeIcon icon={faUsers} className="icon" />
              <h3 className="infoLabel">Public Limit</h3>
              <p className="infoValue">{info.publicAmt}</p>
            </div>
            <div className="infoItem">
              <FontAwesomeIcon icon={faCoins} className="icon" />
              <h3 className="infoLabel">Total Minted</h3>
              <p className="infoValue">{totalMinted}</p>
            </div>
          </div>
        </div>
        <div className="searchbar boss-search">
          <h3>Your PATRON'S</h3>
          <InputGroup className="mb-3 search-id">
            <FormControl
              placeholder="Search by ID"
              aria-label="Search by ID"
              aria-describedby="basic-addon2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </div>

        <div className="userBunnies">
          <div className="bunniesGrid">
            {userBunnies.filter((bunny) => bunny.id.toString().includes(search))
              .length > 0 ? (
              userBunnies
                .filter((bunny) => bunny.id.toString().includes(search))
                .map((bunny) => (
                  <div key={bunny.id} className="bunnyCard">
                    <div className="imageContainer">
                      <Tilt options={{ max: 25 }}>
                        <img
                          src={bunny.uri}
                          alt={`Patron #${bunny.id}`}
                          className="bunny-img"
                          onClick={() => handleModal(bunny)}
                        />

                        <div
                          className="bunnyDetails"
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                          }}
                        >
                          <p>
                            Patron #{bunny.id} {bunny.description}
                          </p>
                        </div>
                        <div className="rarityBadge">
                          {bunny.rarityCategory === "Common" && (
                            <>
                              <FontAwesomeIcon icon={faStar} /> Common
                            </>
                          )}
                          {bunny.rarityCategory === "Uncommon" && (
                            <>
                              <FontAwesomeIcon icon={faGem} /> Uncommon
                            </>
                          )}
                          {bunny.rarityCategory === "Rare" && (
                            <>
                              <FontAwesomeIcon icon={faFire} /> Rare
                            </>
                          )}
                          {bunny.rarityCategory === "Ultra Rare" && (
                            <>
                              <FontAwesomeIcon icon={faCrown} /> Ultra Rare
                            </>
                          )}
                        </div>
                      </Tilt>
                    </div>

                    <div className="bunnyActions">
                      <button
                        className="stakeButton customButton"
                        onClick={() => {
                          window.location.href = "/nft-vault";
                        }}
                        aria-label="Stake on Vaults"
                        data-tooltip="Stake on Vaults"
                      >
                        Stake
                      </button>
                      <button
                        className="tradeButton customButton"
                        onClick={() => {
                          window.open(
                            "https://www.external-site.com",
                            "_blank"
                          );
                        }}
                        aria-label="Trade on Blur"
                        data-tooltip="Trade on Blur"
                      >
                        Trade
                      </button>
                    </div>
                  </div>
                ))
            ) : (
              <div className="bossempty-state">
                <h3>You do not have any NFTs.</h3>
                <p>Buy or mint your NFTs to start your collection!</p>
                <div className="btn-container">
                  <a
                    href="https://www.externalsite.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-link"
                  >
                    <button className="empty-btn">Buy NFT</button>
                  </a>
                  <a href="/mint-page" className="button-link">
                    <button className="empty-btn">Mint NFT</button>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => handleModal(null)}
        centered
        className="bg-modal"
      >
        {selectedBunny && selectedBunnyMetadata && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Patron #{selectedBunny.id.toString()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tilt options={{ max: 25 }}>
                <img
                  src={selectedBunny.uri}
                  alt={`Patron #${selectedBunny.id}`}
                  className="img-fluid"
                />
              </Tilt>
              <h5 className="boss-name">{selectedBunnyMetadata.name}</h5>
              <p className="boss-description">
                {selectedBunnyMetadata.description}
              </p>

              <h6 className="attributes-title">
                <FontAwesomeIcon icon={faListAlt} />{" "}
                {/* this line will show the icon */}
                Attributes
              </h6>
              <ul className="attributes-list">
                {selectedBunnyMetadata.attributes.map((attribute, index) => (
                  <li key={index} className="attributes-card">
                    <FontAwesomeIcon icon={faCheckSquare} />
                    <span className="attribute-type">
                      {attribute.trait_type}
                    </span>
                    <span className="attribute-value">{attribute.value}</span>
                  </li>
                ))}
              </ul>
            </Modal.Body>
          </>
        )}
      </Modal>

      <AlertModal
        isOpen={alertModalOpen}
        message={alertModalMessage}
        onClose={closeAlertModal}
      />
      <Footer />
    </div>
  );
}

export default CloudBunnyPage;
