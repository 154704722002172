import React from 'react';
import { AiOutlineTwitter } from 'react-icons/ai';
import { RiDiscordFill } from 'react-icons/ri';
import { BiBook } from 'react-icons/bi'; // Assuming BiBook as a placeholder for GitBook
import '../assets/footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <p className='footer-text'>ElPatron© All Right Reserved</p>
      <div className='social-icons'>
        <a className='social-link' href='https://docs.el-patron.xyz'>
          <BiBook size={24} color='#000' /> {/* Updated icon and link */}
        </a>
        <a className='social-link' href='https://twitter.com/ElPatron_xyz'>
          <AiOutlineTwitter size={24} color='#000' />
        </a>
        {/* <a className='social-link' href='https://discord.gg/dzBNjryAzf'>
          <RiDiscordFill size={24} color='#000' />
        </a> */}
      </div>
    </div>
  );
}

export default Footer;
