import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import axios from "axios";
import BossGameContract from "../artifacts/BossGame.sol/BossGame.json";
import BossTokenContract from "../artifacts/BossToken.sol/BossToken.json";
import {
  BossGameContractAddress,
  networkDeployedTo,
  PatTokenContractAddress,
} from "../utils/contracts-config";
import cloudBunnyContract from "../artifacts/CloudBunny.sol/CloudBunny.json";
import { patronnftContractAddress } from "../utils/contracts-config";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AlertModal from "../components/AlertModal";
import networksMap from "../utils/networksMap.json";
import "../assets/bossgame.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faKey,
  faCheckCircle,
  faPlayCircle,
  faClock,
  faUser,
  faTrophy,
  faGamepad,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

function CryptoBossesGamePage() {
  const data = useSelector((state) => state.blockchain.value);
  const [cryptoBossesGameContract, setCryptoBossesGameContract] =
    useState(null);

  const [commitment, setCommitment] = useState("");
  const [secret, setSecret] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [referrer, setReferrer] = useState("");
  const [participationFee, setParticipationFee] = useState(0);
  const [maxPlaysPerPlayer, setMaxPlaysPerPlayer] = useState(0);
  const [prizePool, setPrizePool] = useState(0);
  const [eventEnd, setEventEnd] = useState(0);
  const [commitPhaseEnd, setCommitPhaseEnd] = useState(0);
  const [revealPhaseEnd, setRevealPhaseEnd] = useState(0);
  const [userBunnies, setUserBunnies] = useState([]);
  const [bossTokenContract, setBossTokenContract] = useState(null);
  const [playHistory, setPlayHistory] = useState([]);
  const [approvedTokens, setApprovedTokens] = useState({});
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [currentEventPlayerCount, setCurrentEventPlayerCount] = useState(0);
  const [lastEventWinners, setLastEventWinners] = useState([]);

  useEffect(() => {
    console.log("useEffect triggered with data:", data);
    const initializeContract = async () => {
      if (data.network === networksMap[networkDeployedTo]) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const contract = new ethers.Contract(
          BossGameContractAddress,
          BossGameContract.abi,
          signer
        );

        const tokenContract = new ethers.Contract(
          PatTokenContractAddress,
          BossTokenContract.abi,
          signer
        );

        setCryptoBossesGameContract(contract);
        setBossTokenContract(tokenContract);
      }
    };

    initializeContract();
  }, [data]);

  useEffect(() => {
    if (cryptoBossesGameContract && data && data.account) {
      fetchPlayHistory(data.account);
    }
    const interval = setInterval(() => {
      fetchPlayHistory(data.account);
    }, 60000); // Check every 30 seconds

    return () => clearInterval(interval);
  }, [cryptoBossesGameContract, data]);

  useEffect(() => {
    // Load the approved tokens state from local storage when the component mounts
    const storedApprovedTokens = window.localStorage.getItem("approvedTokens");
    if (storedApprovedTokens) {
      setApprovedTokens(JSON.parse(storedApprovedTokens));
    }
  }, []);

  useEffect(() => {
    // Store the approved tokens state in local storage whenever it changes
    window.localStorage.setItem(
      "approvedTokens",
      JSON.stringify(approvedTokens)
    );
  }, [approvedTokens]);

  useEffect(() => {
    const fetchGameData = async () => {
      const participationFee =
        await cryptoBossesGameContract.participationFee();
      const maxPlaysPerPlayer =
        await cryptoBossesGameContract.maxPlaysPerPlayer();
      const prizePool = await cryptoBossesGameContract.prizePool();
      const eventEnd = await cryptoBossesGameContract.eventEnd();
      const commitPhaseEnd = await cryptoBossesGameContract.commitPhaseEnd();
      const revealPhaseEnd = await cryptoBossesGameContract.revealPhaseEnd();
      const playerCount =
        await cryptoBossesGameContract.getCurrentEventPlayerCount();

      setParticipationFee(ethers.utils.formatEther(participationFee));
      setMaxPlaysPerPlayer(maxPlaysPerPlayer.toString());
      setPrizePool(ethers.utils.formatEther(prizePool));
      setEventEnd(eventEnd.toString());
      setCommitPhaseEnd(safeToNumber(commitPhaseEnd));
      setRevealPhaseEnd(safeToNumber(revealPhaseEnd));
      setCurrentEventPlayerCount(safeToNumber(playerCount));
    };

    if (cryptoBossesGameContract) {
      fetchGameData();
    }

    const interval = setInterval(() => {
      fetchGameData();
    }, 60000); // Check every 30 seconds

    return () => clearInterval(interval);
  }, [cryptoBossesGameContract]);

  const fetchPlayHistory = async (playerAddress) => {
    try {
      const historyArray = await cryptoBossesGameContract.getPlayHistory(
        playerAddress
      );

      // Filtering out duplicates based on tokenId
      const uniqueHistoryArray = historyArray.filter(
        (play, index, self) =>
          index === self.findIndex((p) => p.tokenId === play.tokenId)
      );

      setPlayHistory(uniqueHistoryArray);

      console.log("Fetched Play History:", uniqueHistoryArray); // Debugging line
    } catch (error) {
      console.error("Failed to fetch play history:", error);
    }
  };

  const getUserBunnies = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const bunnyContract = new ethers.Contract(
      patronnftContractAddress,
      cloudBunnyContract.abi,
      provider
    );
    const userBunniesIds = await bunnyContract.walletOfOwner(data.account);

    // Fetch the metadata for each NFT owned by the user
    const _userBunnies = await Promise.all(
      userBunniesIds.map(async (bunnyId) => {
        const tokenURI = await bunnyContract.tokenURI(bunnyId);
        const metadata = await axios.get(
          tokenURI.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/")
        );

        return {
          id: bunnyId.toString(),
          uri: metadata.data.image.replace(
            "ipfs://",
            "https://cloudflare-ipfs.com/ipfs/"
          ),
        };
      })
    );

    setUserBunnies(_userBunnies);
  };

  // Fetch the user's NFTs when the component mounts
  useEffect(() => {
    getUserBunnies();
    const interval = setInterval(() => {
      getUserBunnies();
    }, 60000); // Check every 30 seconds

    return () => clearInterval(interval);
  }, []);

  const commit = async (hashedSecret) => {
    setLoading(true);
    setLoadingMessage("Committing...");
    try {
      const tx = await cryptoBossesGameContract.commit(hashedSecret);
      await tx.wait();
      // Open the alert modal with a success message
      alertModalOpen("Commitment made successfully");
    } catch (error) {
      console.error("Commit Error:", error);
      // Open the alert modal with an error message
      setAlertModalMessage("Commitment made successfully");
      setAlertModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const reveal = async () => {
    setLoading(true);
    setLoadingMessage("Revealing...");
    try {
      const tx = await cryptoBossesGameContract.reveal(secret);
      await tx.wait();
      // Display an alert
      setAlertModalMessage("Successfully revealed the secret");
      setAlertModalOpen(true);
    } catch (error) {
      console.error("Reveal Error:", error);
      // Open the alert modal with an error message
      setAlertModalMessage("Failed to reveal.");
      setAlertModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const play = async () => {
    setLoading(true);
    setLoadingMessage("Playing game...");

    // Simply use the referrer address as provided
    let referrerAddress = referrer;

    // Only proceed with the game, don't try to approve the transfer here
    try {
      const tx = await cryptoBossesGameContract.play(tokenId, referrerAddress);
      await tx.wait();
      // Open the alert modal with a success message
      setAlertModalMessage("Started playing successfully");
      setAlertModalOpen(true);
    } catch (error) {
      console.error("Failed to play game:", error);
      // Extract the error message
      const errorMessage = error.data?.data?.message || error.message;
      // Open the alert modal with the error message
      setAlertModalMessage(errorMessage);
      setAlertModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  let eventPhase = "Winner Selection & Reward Distribution";
  let endTime = 0;
  const now = Date.now() / 1000; // Get current time in seconds

  if (now < commitPhaseEnd) {
    eventPhase = "Commit";
    endTime = commitPhaseEnd;
  } else if (now < revealPhaseEnd) {
    eventPhase = "Reveal";
    endTime = revealPhaseEnd;
  }

  function Countdown({ endTime }) {
    const [timeLeft, setTimeLeft] = React.useState(endTime - Date.now() / 1000);

    React.useEffect(() => {
      const interval = setInterval(() => {
        const newTimeLeft = endTime - Date.now() / 1000;
        setTimeLeft(newTimeLeft);
      }, 1000);
      return () => clearInterval(interval);
    }, [endTime]);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = Math.floor(timeLeft % 60);

    return (
      <div className="countdown">
        <span>
          {hours}
          <span className="countdown-label">h</span>
        </span>
        <span>
          {minutes}
          <span className="countdown-label">m</span>
        </span>
        <span>
          {seconds}
          <span className="countdown-label">s</span>
        </span>
        left
      </div>
    );
  }

  const handleCommit = async () => {
    // Convert the commitment to a number.
    const commitmentNumber = Number(commitment);

    // Make sure the number is valid before proceeding.
    if (!isNaN(commitmentNumber)) {
      // Encode the secret as ABI-encoded packed data before hashing it.
      const bytesSecret = ethers.utils.defaultAbiCoder.encode(
        ["uint256"],
        [commitmentNumber]
      );
      const hashedSecret = ethers.utils.keccak256(bytesSecret);

      // Use hashedSecret when calling commit.
      setCommitment(hashedSecret);
      await commit(hashedSecret);
    } else {
      console.error("Commitment must be a number");
    }
  };

  const approveTokenTransfer = async (tokenId, amount) => {
    if (bossTokenContract) {
      setLoading(true);
      setLoadingMessage("Approving token transfer...");

      try {
        const tx = await bossTokenContract.approve(
          BossGameContractAddress,
          ethers.utils.parseEther(amount)
        );
        await tx.wait();
        // Update the state with the approved token
        setApprovedTokens((prevApprovedTokens) => ({
          ...prevApprovedTokens,
          [tokenId]: true,
        }));

        // Open the alert modal with a success message
        setAlertModalMessage("Token transfer approved successfully");
        setAlertModalOpen(true);
        return true;
      } catch (error) {
        console.error("Error approving token transfer:", error);
        // Open the alert modal with an error message
        setAlertModalMessage(
          "Error approving token transfer or transaction rejected"
        );
        setAlertModalOpen(true);
        return false;
      } finally {
        setLoading(false);
      }
    }
  };

  // Input validation function
  const isValidEthereumAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  const totalPages = Math.ceil(playHistory.length / itemsPerPage);

  const currentItems = playHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function LoadingModal() {
    return (
      <div className={`loading-modal ${loading ? "show" : ""}`}>
        <div className="loading-spinner"></div>
        <div className="loading-message">{loadingMessage}</div>
      </div>
    );
  }

  const checkTokenAllowance = async (tokenId) => {
    try {
      const allowance = await bossTokenContract.allowance(
        data.account,
        BossGameContractAddress
      );

      // Check if the allowance is greater than or equal to the participation fee
      const isEnoughAllowance = ethers.utils
        .parseEther(String(participationFee))
        .lte(allowance);

      setApprovedTokens((prev) => ({
        ...prev,
        [tokenId]: isEnoughAllowance,
      }));
    } catch (error) {
      console.error("Failed to check token allowance:", error.message);
    }
  };

  useEffect(() => {
    if (bossTokenContract) {
      const interval = setInterval(() => {
        userBunnies.forEach((bunny) => {
          checkTokenAllowance(bunny.id);
        });
      }, 10000); // Check every 10 seconds

      return () => clearInterval(interval);
    }
  }, [bossTokenContract, userBunnies]);

  function safeToNumber(value) {
    const MAX_SAFE_INTEGER = 9007199254740991; // This is Number.MAX_SAFE_INTEGER
    if (value <= MAX_SAFE_INTEGER) {
      return value.toNumber();
    } else {
      console.warn("BigInt value might be too large for a number!");
      return value.toString();
    }
  }

  const fetchLastEventWinners = async () => {
    if (cryptoBossesGameContract) {
      try {
        const winners = await cryptoBossesGameContract.getLastEventWinners();
        setLastEventWinners(winners);
      } catch (error) {
        console.error("Error fetching last event winners:", error);
      }
    }
  };

  useEffect(() => {
    fetchLastEventWinners();
    const interval = setInterval(() => {
      fetchLastEventWinners();
    }, 60000); // Check every 30 seconds

    return () => clearInterval(interval);
  }, [cryptoBossesGameContract]);

  return (
    <div className="gcontainer">
      <NavBar />
      <AlertModal
        isOpen={alertModalOpen}
        message={alertModalMessage}
        onClose={() => setAlertModalOpen(false)}
      />

      <LoadingModal />

      <div className="game-info-wrapper">
        <h2 className="game-title">
          <FontAwesomeIcon icon={faGamepad} /> Boss Conquest Saga
        </h2>
        <div className="info-grid">
          <div className="info-grid-cell event-phase">
            <h3 className="info-heading">
              <FontAwesomeIcon icon={faClock} className="rotate-icon" />
              Event Phase
            </h3>
            <p className="info-content">{eventPhase}</p>
            {eventPhase !== "Winner Selection & Reward Distribution" && (
              <Countdown className="event-countdown" endTime={endTime} />
            )}
          </div>
          <div className="info-grid-cell participation-fee">
            <h3 className="info-heading">
              <FontAwesomeIcon icon={faDollarSign} className="pulse-icon" />{" "}
              Participation Fee
            </h3>
            <p className="info-content">{participationFee} $BOSS</p>
          </div>
          <div className="info-grid-cell max-plays">
            <h3 className="info-heading">
              <FontAwesomeIcon icon={faUser} className="bounce-icon" /> Max
              Plays Per Player
            </h3>
            <p className="info-content">{maxPlaysPerPlayer}</p>
          </div>
          <div className="info-grid-cell prize-pool">
            <h3 className="info-heading">
              <FontAwesomeIcon icon={faTrophy} className="pulse-bounce-icon" />{" "}
              Prize Pool
            </h3>
            <p className="info-content">{prizePool} $BOSS</p>
          </div>

          <div className="info-grid-cell event-end">
            <h3 className="info-heading">
              <FontAwesomeIcon icon={faClock} className="rotate-icon" /> Event
              Ends in
            </h3>

            {eventEnd >= Date.now() / 1000 ? (
              <Countdown className="event-countdown" endTime={eventEnd} />
            ) : (
              <div className="event-ended-message">Event Ended</div>
            )}
          </div>
          <div className="info-grid-cell current-player-count">
            <h3 className="info-heading">
              <FontAwesomeIcon icon={faUser} className="rotate-pulse-icon" />{" "}
              Current Player Count
            </h3>
            <p className="info-content">{currentEventPlayerCount}</p>
          </div>
        </div>
      </div>

      <div className="last-event-winners">
    <h3><i className="fas fa-trophy"></i> Last Event Winners</h3>
    {lastEventWinners.length === 0 && <p>No winners in the last event.</p>}
    {lastEventWinners.map((winner, index) => (
      <div key={index} className="winner-details">
        <p><i className="fas fa-map-marker-alt"></i> Address: {winner.winnerAddress}</p>
        <p><i className="fas fa-key"></i> Winning Token ID: {winner.winningTokenId.toString()}</p>
        <p>
          <i className="fas fa-coins"></i> Prize Amount: {parseFloat(ethers.utils.formatEther(winner.prizeAmount)).toFixed(4)} $BOSS
        </p>
      </div>
    ))}
</div>


      <div className="participation-board">
        <h3 className="participation-title">How to Participate</h3>
        <ol className="participation-steps">
          <li>Select a Boss NFT from your collection below.</li>
          <li>Commit your secret(A Number) and keep it safe.</li>
          <li>Approve the token for transfer.</li>
          <li>Play by providing the referrer's address (if any).</li>
          <li>
            During the reveal phase, provide your secret to reveal your move.
          </li>
          <li>Check your play history to see if your boss won!</li>
        </ol>
      </div>

      <div className="input-group">
        <div className="input-field">
          <FontAwesomeIcon icon={faLock} />
          <input
            className="input-text"
            type="text"
            maxLength="10"
            value={commitment}
            onChange={(e) => setCommitment(e.target.value)}
            placeholder="Enter your secret (max 10 chars)"
          />
          <button className="input-btn" onClick={handleCommit}>
            <FontAwesomeIcon icon={faKey} /> Commit
          </button>
        </div>

        <div className="input-field">
          <FontAwesomeIcon icon={faLock} />
          <input
            className="input-text"
            type="text"
            value={secret}
            onChange={(e) => setSecret(e.target.value)}
            placeholder="Secret"
          />
          <button className="input-btn" onClick={reveal}>
            <FontAwesomeIcon icon={faKey} /> Reveal
          </button>
        </div>
      </div>

      <div className="nft-container">
        <div className="nft-grid">
          {userBunnies.map((bunny) => (
            <div key={bunny.id} className="nft-card">
              <div className="nft-image-container">
                <img src={bunny.uri} className="nft-image" />
              </div>
              <div className="nft-info">
                <h2 className="nft-title">NFT ID: {bunny.id}</h2>
                <div className="nft-input-group">
                  <span className="nft-input-label">Referrer</span>
                  <input
                    className="nft-input"
                    placeholder="Referrer"
                    onChange={(e) => setReferrer(e.target.value)}
                  />
                </div>
                {!approvedTokens[bunny.id] ? (
                  <button
                    className="nft-button nft-button-approve"
                    onClick={() => {
                      setTokenId(bunny.id);
                      approveTokenTransfer(bunny.id, participationFee);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} /> Approve Token
                  </button>
                ) : (
                  <button
                    className="nft-button nft-button-play"
                    disabled={!isValidEthereumAddress(referrer)}
                    onClick={() => {
                      setTokenId(bunny.id);
                      play();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlayCircle} /> Play
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="play-history">
        <h3>Your Play History</h3>
        {playHistory && playHistory.length > 0 ? (
          <div className="history-table">
            {currentItems.map((play) => (
              <div key={play.tokenId.toString()} className="history-row">
                <p className="history-item history-token">
                  Token ID: {play.tokenId.toString()}
                </p>
                <p className="history-item history-won">
                  Won: {play.won ? "Yes" : "No"}
                </p>
                <p className="history-item history-time">
                  Time:{" "}
                  {new Date(safeToNumber(play.time) * 1000).toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-history">No play history available.</p>
        )}
        {/* Pagination UI */}
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((num) => (
            <button
              key={num}
              onClick={() => setCurrentPage(num)}
              className={currentPage === num ? "active" : ""}
            >
              {num}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CryptoBossesGamePage;
