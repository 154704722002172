import React from "react";
import "../assets/roadmap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faRocket,
  faPlayCircle,
  faGamepad,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

class Task {
  constructor(description, completed) {
    this.description = description;
    this.completed = completed;
  }
}

class Phase {
  constructor(title, isActive, tasks, icon) {
    this.title = title;
    this.isActive = isActive;
    this.tasks = tasks.map((task) => new Task(...task));
    this.icon = icon;
  }
}

const phases = [
  new Phase(
    "Initiation",
    false,
    [
      ["Crypto Bosses DeFi NFT Conceptualization.", true],
      ["Establishing the NFT Arts and Staking Vaults.", true],
      ["Forming Partnerships.", true],
      ["Initial Dapp Launnch.", true],
    ],
    faLightbulb
  ),
  new Phase(
    "Execution",
    true,
    [
      ["Crypto Boss Vault Creation.", true],
      ["Initial System Testing.", true],
      ["Testnet Deployment with Test NFTs and Vaults", true],
      ["Unveiling NFT Sneak Peeks.", false],
      ["Early Boss Campaigns.", false],
    ],
    faRocket
  ),
  new Phase(
    "Launch",
    false,
    [
      ["Grand Release of NFTs and $BOSS Token", false],
      ["Release of Staking Vault.", false],
      ["Partnerships with Dexs and NFT Marketplaces", false],
      ["Integration of DeFi Utilities with Boss Token", false],
    ],
    faPlayCircle
  ),
  new Phase(
    "Post-Launch",
    false,
    [
      ["Development and Quality Assurance of the Boss Game.", false],
      ["Integration of the Boss Game into the System", false],
      ["Grand Opening of the Boss Card Game with Launch Campaigns", false],
    ],
    faGamepad
  ),
];

const TaskComponent = ({ task }) => (
  <li className="task">
    <FontAwesomeIcon
      icon={faCheckCircle}
      color={task.completed ? "green" : "red"}
    />
    {task.description}
  </li>
);

const PhaseComponent = ({ phase, index }) => (
  <div
    className={`phase ${phase.isActive ? "active" : ""}`}
    style={{ "--i": index }}
  >
    <h2 className="phase-title">
      <FontAwesomeIcon icon={phase.icon} /> {phase.title}
    </h2>
    <ul className="task-list">
      {phase.tasks.map((task, id) => (
        <TaskComponent key={id} task={task} />
      ))}
    </ul>
  </div>
);

const Roadmap = () => (
  <div className="roadmap">
    <h1 className="roadmap-title">Bosses DeFi NFT Roadmap</h1>
    <div className="roadmap-grid">
      {phases.map((phase, index) => (
        <PhaseComponent key={index} phase={phase} index={index} />
      ))}
    </div>
  </div>
);

export default Roadmap;
